var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-autocomplete',{staticClass:"gateway-selector",attrs:{"items":_vm.all_countries,"return-object":"","prepend-icon":"","item-text":"country","dense":"","chips":"","small-chips":"","deletable-chips":"","multiple":"","rounded":"","solo":"","clearable":"","light":"","single-line":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"black--text ml-1"},[_vm._v("SELECT GATEWAYS")])]},proxy:true},{key:"prepend-inner",fn:function(){return [_c('v-img',{attrs:{"width":"20","src":require('@/assets/base-station-signal.svg'),"alt":"''"}})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',{staticClass:"mr-1"},[(index < 2)?_c('country-flag',{staticClass:"country-flag",attrs:{"country":item.alpha3.toLowerCase(),"size":"small","rounded":""}}):_vm._e()],1),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selected_countries.length - 2)+") ")]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"my-0 mr-2"},[_c('v-checkbox',{attrs:{"ripple":false,"color":"primary","input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('div',{staticClass:"d-flex align-center mr-2"},[_c('country-flag',{staticClass:"country-flag",attrs:{"country":item.alpha3.toLowerCase(),"size":"small","rounded":""}})],1),_c('div',[_vm._v(_vm._s(item.country))])])],1)],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.selected_countries),callback:function ($$v) {_vm.selected_countries=$$v},expression:"selected_countries"}})}
var staticRenderFns = []

export { render, staticRenderFns }