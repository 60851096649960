<template>
  <v-container class="graph-wrapper d-flex align-items-center">
    <v-card outlined class="pa-3" dark>
      <div class="d-none d-md-block">
        <v-tabs v-model="active_tab" class="pb-3" @change="change_graphs_time_range">
          <v-tab v-for="item in time_ranges" :key="item">{{ item }}</v-tab>
          <v-tab disabled v-if="current_point !== ''">DATE: {{ current_point }}</v-tab>
        </v-tabs>
      </div>
      <div class="d-block d-md-none px-4">
        <v-select
            :items="time_ranges.map((range, idx) => {return {value: idx, text: range}})"
            v-model="active_tab"
            dark
            @change="change_graphs_time_range"
            class="mb-2"
        />
        <div class="mb-4" v-if="current_point !== ''">DATE: {{ current_point }}</div>
      </div>
      <div id="container" v-on:mousemove="update"></div>
    </v-card>
  </v-container>
</template>

<script>
import Highcharts from 'highcharts/highstock';
import boost from "highcharts/modules/boost";
import {PointsStore} from "@/store";
import { storeToRefs } from 'pinia';

boost(Highcharts);

import {Series, HighchartsGraph, update_tooltips} from "@/utils/highcharts";
import colors from 'vuetify/lib/util/colors';
import consts from "@/consts";

export default {
  name: "DataGraph",
  setup() {
    const points_store = PointsStore();
    const {points} = storeToRefs(points_store);
    return {points_store, points};
  },
  methods: {
    update: function(event) {
      this.current_point = update_tooltips(event).name;
    },
    change_graphs_time_range: function (value) {
      this.altitude_graph.set_data(0, this.altitude.generate(consts.GRAPH_TIME_RANGES[value]));
      this.temperature_graph.set_data(0, this.temperature.generate(consts.GRAPH_TIME_RANGES[value]));
      this.temperature_graph.set_data(1, this.mcu_temperature.generate(consts.GRAPH_TIME_RANGES[value]));
      this.battery_graph.set_data(0, this.battery_vcc.generate(consts.GRAPH_TIME_RANGES[value]));
      this.update();
    },
    init_graphs() {
      this.altitude = new Series(
          'altitude', 'altitude',
          'MASL', this.path_points, 'spline',
          [colors.pink.darken1]
      );
      this.altitude_series = this.altitude.generate(12);

      this.temperature = new Series(
          'temperature', 'temperature',
          '&#8451', this.path_points, 'spline',
          [
            colors.green.accent4, colors.teal.lighten2, colors.lightBlue.darken4
          ]
      );
      this.temperature_series = this.temperature.generate(12);

      this.mcu_temperature = new Series(
          'mcu_temperature', 'mcu temperature',
          '&#8451', this.path_points, 'spline',
          [
            colors.green.accent3, colors.green.accent2, colors.lightBlue.accent1,
            colors.lightBlue.accent2, colors.lightBlue.accent4
          ]
      );
      this.mcu_temperature_series = this.mcu_temperature.generate(12);

      this.battery_vcc = new Series(
          'vcc', 'battery vcc',
          'V', this.points, 'spline',
          [
            colors.lightGreen.accent3, colors.lightGreen.accent2, colors.lime.accent1,
            colors.orange.accent2, colors.red.accent4
          ]
      );
      this.battery_vcc_series = this.battery_vcc.generate(12);

      this.altitude_graph = new HighchartsGraph([this.altitude_series], 'altitude');
      this.altitude_graph.initialize();

      this.temperature_graph = new HighchartsGraph(
          [this.temperature_series, this.mcu_temperature_series],
          'temperature'
      );
      this.temperature_graph.initialize();

      this.battery_graph = new HighchartsGraph([this.battery_vcc_series], 'battery vcc');
      this.battery_graph.initialize();
    }
  },
  mounted() {
    this.path_points = this.points_store.points;
    this.init_graphs();
  },
  watch: {
    points(points) {
      let new_point = points.at(0);
      this.altitude_graph.add_data_point(0, [new_point.time, new_point.altitude]);
      this.temperature_graph.add_data_point(0, [new_point.time, new_point.temperature]);
      this.temperature_graph.add_data_point(1, [new_point.time, new_point.mcu_temperature]);
      this.battery_graph.add_data_point(0, [new_point.time, new_point.vcc]);
    }
  },
  data () {
    return {
      current_point: '',
      active_tab: 0,
      graph: {},
      time_ranges: ['LAST 12H', 'LAST 24H', 'LAST 36H', 'ALL']
    }
  }
}
</script>

<style lang="scss">
.graph-wrapper {
  .v-card {
    width: 100%;
    #container {
      width: 100%;
      color: white !important;
      background-color: grey;
      .chart {
        height: 220px !important;
      }
    }
  }
}

.highcharts-plot-background {
  fill: #252525;
}
</style>
