<template>
  <v-autocomplete
      v-model="selected_countries"
      :items="all_countries"
      return-object
      prepend-icon=""
      item-text="country"
      dense
      chips
      small-chips
      deletable-chips
      multiple
      rounded
      solo
      clearable
      light
      single-line
      class="gateway-selector"
  >
    <template v-slot:label>
      <span class="black--text ml-1">SELECT GATEWAYS</span>
    </template>
    <template v-slot:prepend-inner>
      <v-img width="20" :src="require('@/assets/base-station-signal.svg')" alt="''"/>
    </template>
    <template v-slot:selection="{item, index}">
      <span class="mr-1">
        <country-flag v-if="index < 2" :country="item.alpha3.toLowerCase()" size='small' class="country-flag" rounded/>
      </span>
      <span v-if="index === 2" class="grey--text text-caption">
          (+{{ selected_countries.length - 2 }})
      </span>
    </template>
    <template v-slot:item="{item, attrs, on}">

      <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
        <v-list-item-action class="my-0 mr-2">
          <v-checkbox :ripple="false" color="primary" :input-value="active"></v-checkbox>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>
            <v-row no-gutters align="center">
              <div class="d-flex align-center mr-2">
                <country-flag :country="item.alpha3.toLowerCase()" size='small' class="country-flag" rounded/>
              </div>
              <div>{{ item.country }}</div>
            </v-row>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

<!--      <div class="d-flex align-content-center">-->
<!--        <v-checkbox :ripple="false" :input-value="active"></v-checkbox>-->
<!--        <div class="d-flex align-center mr-2">-->
<!--          <country-flag :country="item.alpha3.toLowerCase()" size='small' class="country-flag" rounded/>-->
<!--        </div>-->
<!--        <div>{{ item.country }}</div>-->
<!--      </div>-->
    </template>
  </v-autocomplete>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import iso from 'iso-3166-1';
import consts from "@/consts";
import {storeToRefs} from 'pinia';

import {PointsStore} from "@/store";

export default {
  name: "GatewayCountrySelector",
  components: {CountryFlag},
  setup() {
    const points_store = PointsStore();
    const {gateways_countries} = storeToRefs(points_store);
    return {points_store, gateways_countries};
  },
  data() {
    return {
      iso,
      consts,
    }
  },
  computed: {
    all_countries() {
      return iso.all().filter(country => consts.EUROPEAN_COUNTRIES.includes(country.alpha2.toLowerCase()))
    },
    selected_countries: {
      get() {
        return this.points_store.gateways_countries;
      },
      set(value) {
        this.points_store.gateways_countries = value;
        this.$cookies.remove(consts.COUNTRIES_COOKIE);
        this.$cookies.set(consts.COUNTRIES_COOKIE, {countries: value});
      }
    },
  },
}
</script>

<style>
.v-text-field--rounded>.v-input__control>.v-input__slot {
  padding: 0 10px !important;
}
.v-label {
  font-size: 0.85em !important;
}
</style>
