import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import colors from 'vuetify/lib/util/colors';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
    customProperties: true,
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            dark: {
                primary: colors.purple,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
                warning: colors.red.accent3,
                background: colors.grey.darken4,
            },
        },
    }
};

export default new Vuetify(opts);
