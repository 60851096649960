import Highcharts from 'highcharts/highstock';
import colors from 'vuetify/lib/util/colors';

export class Series {
    constructor(name, text, unit, data, type, colors) {
        this.name = name;
        this.unit = unit;
        this.type = type;
        this.color = this.get_color(colors);
        this.data = data.map((x) => {return [x.time, parseFloat(x[name])]}).reverse();
    }

    get_color(colors) {
        if (colors.length > 1) {
            let step = 1 / (colors.length - 1);
            return {
                linearGradient: [0, 0, 0, 129],
                stops: colors.map((color, index) => [index * step, color])
            }
        } else {
            return colors[0];
        }
    }

    generate(time_range) {
        let data = this.data;
        if (time_range <= 36) {
            let ranged_date = new Date(new Date().getTime() - (time_range * 60 * 60 * 1000));
            data = data.filter(point => new Date(point[0]) > ranged_date);
        }
        return {
            name: this.name,
            data: data,
            unit: this.unit,
            type: this.type,
            color: this.color,
            tooltip: {
                valueSuffix: ' ' + `${this.unit}`,
            }
        }
    }
}

export class HighchartsGraph {
    constructor(series, chart_name) {
        this.series = series;
        this.chart_name = chart_name;

        this.chart_div = document.createElement('div');
        this.chart_div.className = 'chart';
        document.getElementById('container').appendChild(this.chart_div);

        Highcharts.Pointer.prototype.reset = function () {
            return undefined;
        };

        Highcharts.Point.prototype.highlight = function (event) {
            event = this.series.chart.pointer.normalize(event);
            this.onMouseOver(); // show the hover marker
            this.series.chart.tooltip.refresh(this); // show the tooltip
            this.series.chart.xAxis[0].drawCrosshair(event, this); // show the crosshair
        };

    }

    set_data(series_index, data) {
        this.chart.series[series_index].setData(data.data);
    }

    add_data_point(series_index, point) {
        this.chart.series[series_index].addPoint(point);
    }

    initialize() {
        this.chart = Highcharts.chart(this.chart_div, {
            chart: this.get_chart_options(),
            plotOptions: this.get_plot_options(),
            title: this.get_title_options(),
            xAxis: this.get_axis_x_options(),
            yAxis: this.get_axis_y_options(),
            tooltip: this.get_tooltip_options(),
            response: this.get_responsive_options(),
            series: this.series,
            zoomType: {
                enabled: true
            },
            credits: {
                enabled: false
            },
            legend: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
            lang: {
                noData: "No data for the selected time range",
            }
        });
    }
    get_chart_options() {
        return {
            marginLeft: 55,
            spacingTop: 35,
            spacingBottom: 20,
            backgroundColor: colors.grey.darken4,
            zoomType: 'x',
            style: {
                'font-family': 'JetBrains Mono, monospace',
                color: colors.shades.white,
            },
        }
    }
    get_plot_options() {
        return {
            spline: {
                marker: {
                    enabled: true,
                    radius: 0.5
                },
                connectNulls: true,
                lineWidth: 3,
            },
        }
    }
    get_title_options() {
        return {
            text: this.chart_name,
            align: 'left',
            margin: 0,
            x: 30,
            y: -10,
            style: {
                'font-size': '0.9em',
                color: colors.shades.white,
            },
        }
    }
    get_axis_x_options() {
        return {
            visible: false,
            gridLineWidth: 1,
            crosshair: true,
            gridLineColor: colors.grey.darken2,
            tickWidth: 1,
            labels: {
                style: {
                    color: colors.shades.white
                }
            },
            type: 'category',
            events: {
                setExtremes: function(event) {
                    if (event.trigger !== "syncExtremes") {
                        // prevent feedback loop
                        Highcharts.each(Highcharts.charts, (chart) => {
                            if (chart !== this.chart) {
                                if (chart.xAxis[0].setExtremes) {
                                    // it is null while updating
                                    chart.xAxis[0].setExtremes(event.min, event.max, undefined, false, {
                                        trigger: "syncExtremes"
                                    });
                                }
                            }
                        });
                    }
                }
            },
            tickInterval: 60000,
            marker: {
                enabled: false
            },
        }
    }
    get_axis_y_options() {
        return {
            title: {
                text: null
            },
            labels: {
                style: {
                    color: colors.shades.white
                }
            },
            gridLineWidth: 1,
            gridLineColor: colors.grey.darken2,
            tickWidth: 1,
        }
    }
    get_tooltip_options() {
        return {
            shared: true,
            positioner: function() {
                return {x: this.chart.chartWidth - this.label.width, y: 5};
            },
            borderWidth: 0,
            backgroundColor: 'none',
            headerFormat: '',
            shadow: false,
            style: {
                fontSize: '1em',
                color: colors.shades.white,
            },
            crosshair: {
                width: 1.5,
                dashStyle: 'solid',
                color: 'white'
            },
            valueDecimals: true,
        }
    }
    get_responsive_options() {
        return {
            rules: [{
                condition: {
                    maxWidth: 768
                },
                chartOptions: {
                    xAxis: {
                        tickInterval: 50,
                        labels: {
                            formatter: function () {
                                return this.value.slice(5);
                            }
                        },
                    },
                }
            }]
        }
    }
}

export function update_tooltips(original_event) {

    if (original_event === undefined) {
        for (let index = 0; index < Highcharts.charts.length; index++) {
            let chart = Highcharts.charts[index];
            chart.tooltip.hide();
            chart.xAxis[0].hideCrosshair();
        }
    }

    let result = {};
    for (let index = 0; index < Highcharts.charts.length; index++) {
        let chart = Highcharts.charts[index];
        let event = chart.pointer.normalize(original_event); //find coordinates within the chart
        let point;
        for (let j = 0; j < chart.series.length && !point; ++j) {
            point = chart.series[j].searchPoint(event, true);
        }

        result = point;

        if (point) {
            if (event.type === "mousemove") {
                point.onMouseOver();
                chart.xAxis[0].drawCrosshair(event, point);
            } else {
                point.onMouseOut();
                chart.tooltip.hide(point);
                chart.xAxis[0].hideCrosshair();
            }
        } else {
            return {name: ''}
        }
    }
    return result;
}
