<template>
  <v-container class="table-wrapper d-flex align-items-center">
    <v-card class="pa-3 my-auto" dark outlined>
      <v-data-table
          :headers="headers"
          :items="points"
          :sort-desc="[false, true]"
          multi-sort
          dense
          dark
          color="black"
          :search="search"
          class="pa-2"
      >
        <template v-slot:top>
          <v-toolbar flat class="mb-3">
            <v-text-field
                v-model="search"
                rounded
                hide-details
                outlined
                dense
                class="col-12 col-m-3 pl-0 mx-0 text--black"
            >
              <template v-slot:append>
                <v-icon color="red">mdi-magnify</v-icon>
              </template>
              <template v-slot:label><span class="custom-label body-2">search</span></template>
            </v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:[`header.name`]="{ header }">
          {{ header.text.toUpperCase() }}
          {{ header.icon }}
          <v-icon small color="red">{{ header.icon }}</v-icon>
        </template>
        <template v-slot:[`item.latitude`]="{ item }">
          {{ Number(item.latitude).toFixed(4) }}
        </template>
        <template v-slot:[`item.longitude`]="{ item }">
          {{ Number(item.longitude).toFixed(4) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import {PointsStore} from "@/store";
export default {
  name: "DataTable",
  setup() {
    const points_store = PointsStore();
    return {points_store};
  },
  data() {
    return {
      search: ''
    }
  },
  computed: {
    points() {
      return this.points_store.points;
    },
    headers() {
      return [
        {text: 'time', value: 'time', width: '200', icon: 'mdi-calendar-clock'},
        {text: 'MCU temp [℃]', value: 'mcu_temperature', width: '140'},
        {text: 'temp [℃]', value: 'temperature', width: '140'},
        {text: 'latitude', value: 'latitude', width: '140'},
        {text: 'longitude', value: 'longitude', width: '140'},
        {text: 'altitude [MASL]', value: 'altitude', width: '170'},
        {text: 'pressure [hPa]', value: 'pressure', width: '170'},
        {text: 'battery vcc [V]', value: 'vcc', width: '170'},
        {text: 'gateways', value: 'gateways', width: '130'},
        {text: 'accuracy', value: 'accuracy', width: '130'},
        {text: 'spreading factor', value: 'spreading_factor', width: '130'},
      ];
    }
  }
}
</script>

<style scoped>
>>> .v-data-table>.v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 30px !important;
}
>>> .v-data-table--mobile>.v-data-table__wrapper tbody {
  margin-top: 20px;
}
>>> .theme--dark.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
  border-bottom: none !important;
}

>>> tbody .v-data-table__mobile-table-row {
  border-bottom: thin solid hsla(0,0%,100%,.12);
  padding-bottom: 10px;
  padding-top: 10px;
}

>>> tbody .v-data-table__mobile-table-row td {
  border-bottom: none !important;
}

</style>
