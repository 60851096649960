import balloon from '@/assets/balon.svg';
import map_pin from '@/assets/map_pin.svg';
import lora_gate_online from '@/assets/base-station-signal.svg';
import lora_gate_offline from '@/assets/base-station-no-signal.svg';
import constants from "@/consts";
import 'leaflet.markercluster';

export class WindyHandler {

    constructor(balloon_points) {
        this.lora_gate_online_icon = this.create_gateway_icon(constants.LORA_GATE_STATUS.ONLINE);
        this.lora_gate_offline_icon = this.create_gateway_icon(constants.LORA_GATE_STATUS.OFFLINE);
        this.balloon_icon = this.create_balloon_icon();
        this.map_pin_icon = this.create_map_pin_icon();
        this.available_pressure_levels = [];
        this.balloon_points = balloon_points;

        this.options = {
            key: constants.WINDY.API_KEY,
            lat: this.balloon_points.length > 0 ? balloon_points.at(-1).latitude : 49,
            lon: this.balloon_points.length > 0 ? balloon_points.at(-1).longitude : 16,
            zoom: constants.WINDY.DEFAULT_ZOOM,
        }

        this.windy_map = null;
        this.windy_api = null;
    }

    init_windy_map(gateways, balloon_path) {
        let that = this;
        window.windyInit(this.options, async (windyAPI) => {
            const {map} = windyAPI;
            that.windy_api = windyAPI;
            that.windy_map = map;
            that.create_lora_gateway_clusters(gateways);
            that.create_balloon_path(balloon_path);
            that.windy_api.store.set('lang', 'en');
            this.available_pressure_levels = that.windy_api.store.get('availLevels').map(level => level.replace('h', '')).slice(2);
            this.set_closest_pressure_point(balloon_path.at(-1).pressure);

        });
    }

    set_closest_pressure_point(current_pressure) {
        const closest = this.available_pressure_levels.reduce((a, b) => {
            return Math.abs(b - current_pressure) < Math.abs(a - current_pressure) ? b : a;
        });
        this.windy_api.store.set('level', `${closest}h`);
    }

    change_map_center(point) {
        if (this.windy_map != null) {
            this.windy_map.panTo(new window.L.LatLng(point.latitude, point.longitude));
        }
    }

    create_balloon_icon() {
        return new window.L.icon({
            iconUrl: balloon,
            iconSize: [54, 54],
            iconAnchor: [0, 37],
            popupAnchor: [18, -17],
        });
    }

    create_map_pin_icon() {
        return new window.L.icon({
            iconUrl: map_pin,
            iconSize: [25, 35],
            iconAnchor: [12, 37],
            popupAnchor: [1, -37],
        });
    }

    create_gateway_icon(status) {
        return new window.L.icon({
            iconUrl: status === constants.LORA_GATE_STATUS.ONLINE ? lora_gate_online : lora_gate_offline,
            iconSize: [44, 44],
            iconAnchor: [17, 17],
            popupAnchor: [4.5, -10],
        });
    }

    create_lora_gateway_clusters(gate_locations) {
        this.marker_clusters = new window.L.MarkerClusterGroup();
        gate_locations.forEach((gate) => {
            if (gate['location'] !== undefined) {
                this.marker_clusters.addLayer(new window.L.marker(
                    [gate.location.latitude, gate.location.longitude],
                    {icon: gate.online ? this.lora_gate_online_icon : this.lora_gate_offline_icon}
                  ).bindPopup(this.generate_gateway_popup_text(gate))
                );
            }
        });
        this.marker_clusters.addTo(this.windy_map);
    }

    delete_lora_gateway_clusters() {
        this.marker_clusters.clearLayers();
        this.windy_map.removeLayer(this.marker_clusters);
    }

    add_point_to_balloon_path(new_point) {
        let last_marker = this.balloon_markers.shift();
        this.windy_map.removeLayer(last_marker);
        this.create_balloon_path([new_point, this.balloon_points.at(0)]);
        this.balloon_points.unshift(new_point);
    }

    create_balloon_path(balloon_locations) {
        const track = balloon_locations.map(location => [location.latitude, location.longitude]);
        this.balloon_path = new window.L.polyline(track, {
            color: 'white',
            weight: 2,
            dashArray: "3 3",
        }).addTo(this.windy_map);

        this.balloon_markers = [];
        balloon_locations.forEach((location, index) => {
            let marker = new window.L.marker(
                [location.latitude, location.longitude],
                {icon: (index > 0 ? this.map_pin_icon : this.balloon_icon)}
            ).bindPopup(this.generate_balloon_popup_text(location)).addTo(this.windy_map);
            this.balloon_markers.push(marker);
        });
    }

    generate_gateway_popup_text(gate) {
        let leading_zero = (time_entity) => time_entity > 9 ? time_entity : '0' + time_entity;
        let text = `<b>name</b>: ${gate.name}<br>`;
        text += `<b>id</b>: ${gate.id}<br>`;
        let date = new Date(gate.last_seen);
        text += `<b>online</b>: <span style="color:${gate.online ? 'green' : 'red'}"><b>${gate.online}</b></span><br>`;
        let last_seen = `${date.getUTCFullYear()}-${leading_zero(date.getMonth())}-${leading_zero(date.getUTCDate())} `;
        last_seen += `${leading_zero(date.getHours())}:${leading_zero(date.getMinutes())}:${leading_zero(date.getSeconds())}`;
        text += `<b>last seen</b>: ${last_seen}`;
        return text;
    }

    generate_balloon_popup_text(balloon) {
        let text = `<b>time</b>: ${balloon.time}<br>`;
        text += `<b>location</b>: ${Number(balloon.latitude).toFixed(3)} `
        text += `${Number(balloon.longitude).toFixed(3)} | ${balloon.altitude} MASL<br>`;
        text += `<b>temperature</b>: ${balloon.temperature}&#8451;<br>`;
        text += `<b>mcu temperature</b>: ${balloon.mcu_temperature}&#8451;<br>`
        text += `<b>battery</b>: ${balloon.vcc} V`;
        return text;
    }

}


