<template>
  <v-list class="py-0" :key="`data_points-${points.length}`">
    <v-list-item
        v-for="item in points" :key="item.time"
        class="py-0 pl-0 pr-2 points-row"
        @click="go_to_point(item)"
    >
      <div class="d-flex align-content-center mini">
        <v-list-item-avatar :color="item.longitude !== ''? 'red lighten-1' : 'grey darken-1'" size="21" class="my-0 mx-2">
          <v-icon small color="white">mdi-map-marker</v-icon>
        </v-list-item-avatar>
        <div v-if="!expanded">
          <div>{{ item.time.substring(0, 10) }}</div>
          <div>{{ item.time.substring(10) }}</div>
        </div>
      </div>
      <div v-if="expanded" class="py-3 point">
        <div>
          <span><v-icon color="grey darken-1" x-small class="mr-1 mb-1">mdi-calendar-clock</v-icon>{{ item.time }}</span>
          <span>
            <v-icon
              :color="item.vcc > 2.8 ? 'green lighten-1' : (item.vcc > 2.4 ? 'orange lighten-1' : 'red lighten-1')"
              x-small class="mb-1"
            >
              mdi-battery-60</v-icon>
            {{ item.vcc }}V
          </span>
        </div>
        <span><v-icon color="grey darken-1" x-small class="mr-1 mb-1">mdi-thermometer</v-icon>{{ item.temperature }}&#8451;</span>
        <span><v-icon color="grey darken-1" x-small class="mr-1">mdi-chip</v-icon>{{ item.mcu_temperature }}&#8451;</span>
        <span><v-icon color="grey darken-1" x-small class="mr-1 mb-1">mdi-arrow-up-down</v-icon>{{ item.altitude }} MASL</span>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import {PointsStore} from '@/store';
export default {
  name: "DataPoints",
  props: ["expanded"],
  setup() {
    const points_store = PointsStore();
    return {points_store};
  },
  computed: {
    points() {
      return this.points_store.points.slice(0, 50);
    }
  },
  methods: {
    go_to_point(point) {
      if (point.accuracy > 0) {
        this.points_store.update_focused_point(point);
      }
    }
  }

}
</script>

<style scoped lang="scss">
.points-row {
  .mini {
    font-size: 0.6em !important;
  }
  .point {
    font-size: 0.6em !important;
    span:not(:last-child) {
      &:after {
        content: '|';
        color: grey;
        margin-left: 2px;
        margin-right: 2px;
      }
    }
  }
}
.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item {
  justify-content: left;
}
</style>
