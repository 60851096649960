export default Object.freeze({
    GOOGLE_MAPS_API_KEY: 'AIzaSyDsKENV46lY8XMqQJMXiGJ-bXD4Nfsfwmo',
    WINDY: {
        API_KEY: '5vahG1G8mS8PJCQxiW2AGcon98JagWzB',
        DEFAULT_LATITUDE: 14,
        DEFAULT_LONGITUDE: -29,
        DEFAULT_ZOOM: 6
    },
    SERVER_URL: 'https://kamik-api.sgve.eu:5000',
    HISTORY_DATA_POINTS: 'get_data',
    LAST_DATA_POINT: 'get_last_data',
    LORA_TTN_GATES: 'https://www.thethingsnetwork.org/gateway-data/',
    LORA_GATE_STATUS: {
        ONLINE: 'online',
        OFFLINE: 'offline'
    },
    GRAPH_TIME_RANGES: {
        0: 12,
        1: 24,
        2: 36,
        3: 48
    },
    HIDE_MAP: 'hide_map',
    EUROPEAN_COUNTRIES: ['cz', 'sk', 'hu', 'pl', 'at', 'de', 'it', 'si', 'ba', 'hr', 'rs', 'ro', 'ua', 'tr', 'gr', 'al', 'mk', 'bg'],
    COUNTRIES_COOKIE: 'selected_gateways'
    //SELECTED_COUNTRIES: ['cz', 'sk', 'hu', 'ro', 'md', 'ua', 'tr', 'ge'],
    //SELECTED_COUNTRIES: ['cz', 'sk', 'hu', 'ro', 'md', 'ua', 'tr', 'ge', 'ru', 'az'],
});
