<template>
  <v-app class="mb-10" :style="{background: $vuetify.theme.themes.dark.background}">
    <app-bar class="app-bar" :visible="app_bar_visible" @visible="toggle_app_bar"></app-bar>
    <div class="menu-button-wrapper pa-2" v-if="!app_bar_visible">
      <v-btn icon large color="white" @click="app_bar_visible = !app_bar_visible">
        <v-icon large>mdi-menu</v-icon>
      </v-btn>
    </div>
    <div class="map-wrapper d-flex justify-center align-content-center">

      <windy-map v-if="points.length > 0 && display_map !== consts.HIDE_MAP"></windy-map>
      <div v-else-if="!loading" class="d-flex flex-column justify-center">
        <span class="white--text">no points with location <span class="red--text">:(</span></span>
      </div>

      <auto-update v-if="!loading"></auto-update>

      <v-btn v-if="!loading" rounded class="chart-button mx-auto" @click="$vuetify.goTo('.graph-wrapper')">
        graphs & charts
        <v-icon right small color="red">mdi-chevron-double-down</v-icon>
      </v-btn>

      <gateway-country-selector v-if="!loading" class="gateway-selector"></gateway-country-selector>

    </div>
    <v-divider class="mb-12"></v-divider>

    <div class="mb-6">
      <data-graph v-if="points.length > 0"></data-graph>
    </div>

    <div class="my-6">
      <data-table v-if="points.length > 0"></data-table>
    </div>

    <v-divider class="my-12"></v-divider>

    <v-dialog
        v-model="loading"
        persistent
        width="auto"
        class="rounded-circle"
    >
      <v-sheet dark class="pa-8 text-center rounded-circle px-13 py-10">
          <radar-spinner
              :animation-duration="1500"
              :size="160"
              color="text--warning"
          ></radar-spinner>
          <div class="text--warning">fetching fluidum</div>

      </v-sheet>
    </v-dialog>

  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar";
import WindyMap from "@/components/WindyMap";
import DataTable from "@/components/DataTable";
import AutoUpdate from "@/components/AutoUpdate";
import DataGraph from "@/components/DataGraph";
import {PointsStore} from "@/store";
import consts from "@/consts";

import {RadarSpinner} from 'epic-spinners'
import GatewayCountrySelector from "@/components/GatewayCountrySelector";

export default {
  name: 'App',
  components: {
    GatewayCountrySelector,
    WindyMap,
    AppBar,
    DataTable,
    AutoUpdate,
    DataGraph,
    RadarSpinner
  },
  setup() {
    const points_store = PointsStore();
    return {points_store};
  },
  data() {
    return {
      app_bar_visible: true,
      consts,
      display_map: process.env.NODE_ENV,
    }
  },
  async mounted() {
    await this.points_store.get_lora_ttn_gates();
    await this.points_store.get_history_points();
  },
  methods: {
    toggle_app_bar(value) {
      this.app_bar_visible = value;
    }
  },
  computed: {
    points() {
      return this.points_store.points;
    },
    loading() {
      return this.points_store.loading;
    }
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono&display=swap');

#app {
  font-family: 'JetBrains Mono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.menu-button-wrapper {
  position: absolute;
  z-index: 1;
}

.app-bar {
  z-index: 2;
}

.v-dialog {
  box-shadow: none !important;
}

.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
  min-height: 36px!important;
}

.map-wrapper {
  position: relative;
  min-height: 100vh;
  .gateway-selector {
    position: absolute;
    z-index: 1;
    right: 24%;
    top: 1.5%;
    width: 250px;
  }
  .chart-button {
    position: absolute;
    z-index: 1;
    left: 43.1%;
    bottom: 9%;
  }
  .countdown {
    position: absolute;
    z-index: 1;
    right: 6%;
    top: 1.5%
  }
  #windy #logo {
    transform: scale(0.5, 0.5) !important;
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .map-wrapper {
    .gateway-selector {
      right: 16.5%;
      top: 10%;
    }
    .countdown {
      left: 20%;
      top: 4.5%;
    }
    .chart-button {
      left: 26%;
      bottom: 15%;
    }
  }
}

</style>
