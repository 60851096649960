import Router from 'vue-router';

let router = new Router({
    mode: 'history',
    base: '/',
    async scrollBehavior (to) {
        if (to.hash) {
            setTimeout(function() {
                document.querySelector(to.hash).scrollIntoView({ behavior: 'smooth', block: 'center'});}, 100);
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes: [
        {
            path: '/',
            name: 'weather-map',
            component: () => import(/* webpackChunkName: "weather-map" */ '@/components/WindyMap.vue')
        },
    ]
});


export default router;
