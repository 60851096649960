<template>
  <vue-countdown
      @progress="handle_countdown"
      class="countdown"
      :time="5 * 60 * 1000"
      :key="countdown"
      emit-events
      @end="update()"
      ref="countdown"
  >
    <v-btn light rounded dense @click="update()" :loading="show_loading_spinner" min-width="250">
      <transition name="slide-fade" mode="out-in">
        <div :key="button_key">{{ button_text }}</div>
      </transition>
      <v-icon right small color="red">mdi-update</v-icon>
    </v-btn>
  </vue-countdown>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown';
import {PointsStore} from "@/store";

export default {
  name: "AutoUpdate",
  components: {
    VueCountdown,
  },
  setup() {
    const points_store = PointsStore();
    return {points_store};
  },
  data() {
    return {
      search: '',
      countdown: true,
      show_loading_spinner: false,
      finished_loading: true,
      button_text: '',
      button_key: '',
    }
  },
  computed: {
    points() {
      return this.points_store.points;
    },
  },
  methods: {
    handle_countdown(props) {
      if (this.finished_loading) {
        this.button_text = `Auto-update in 0${props.minutes}:${props.seconds < 10 ? '0' + props.seconds : props.seconds}`;
      }
    },
    update() {
      this.show_loading_spinner = true;
      this.finished_loading = false;

      setTimeout(() => {

        this.show_loading_spinner = false;
        const original_length = this.points.length;
        this.points_store.get_last_point();

        if (original_length < this.points.length) {
          this.button_key = 'updated-point';
          this.button_text = 'Got a new point!';

          setTimeout(() => {
            this.finished_loading = true;
            this.button_key = '';
            this.countdown = !this.countdown;
          }, 2000);

        } else {
          this.button_key = 'updated-point';
          this.button_text = 'No new point';

          setTimeout(() => {
            this.finished_loading = true;
            this.button_key = '';
            this.countdown = !this.countdown;
          }, 2000);

        }
      }, 2000);

    }
  }
}
</script>

<style scoped>

</style>
