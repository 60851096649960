import { defineStore } from 'pinia';
import consts from '@/consts';
import Vue from 'vue';
import iso from 'iso-3166-1';

export const PointsStore = defineStore('points', {
    state: () => ({
        points: [],
        gates: [],
        focused_point: null,
        loading: true,
        all_gates: [],
        gateways_countries: ['cz', 'sk', 'hu', 'au'].map(country => iso.whereAlpha2(country))
    }),
    actions: {
        async get_lora_ttn_gates() {
            await Vue.axios.get(consts.LORA_TTN_GATES)
                .then((response) => {
                    let cookie = Vue.$cookies.get(consts.COUNTRIES_COOKIE);

                    let selected_countries = this.gateways_countries;
                    if (cookie !== null && cookie.countries !== undefined) {
                        selected_countries = cookie.countries;
                        this.gateways_countries = selected_countries;
                    } else {
                      Vue.$cookies.set(consts.COUNTRIES_COOKIE, {countries: selected_countries});
                    }

                    this.all_gates = response.data;
                    this.gates = this.filter_gates(selected_countries.map(country => country.alpha2.toLowerCase()));

                }).catch((err) => {
                    console.log(err);
            })
        },
        async get_last_point() {
            Vue.axios.get(`${consts.SERVER_URL}/${consts.LAST_DATA_POINT}`)
                .then((response) => {
                    let point = this.parse_point(response.data[0]);
                    let last_point = this.points.at(0);
                    if (last_point.time !== point.time) {
                        this.points.unshift(point);
                        this.update_focused_point(point);
                    }
                }).catch((err) => {
                    console.log(err);
                });
        },
        async get_history_points() {
            this.loading = true;
            Vue.axios.get(`${consts.SERVER_URL}/${consts.HISTORY_DATA_POINTS}`)
                .then((response) => {
                    this.points = (response.data.sort((a, b) => parseInt(a.id) - parseInt(b.id))).reverse();
                    this.points = this.points.map(point => this.parse_point(point));
                    // deduplicate by time
                    this.points = this.points.filter((item, idx, self) => idx === self.findIndex(t => t.time === item.time));
                    this.loading = false;
                    this.update_focused_point(this.points.at(-1));
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
        parse_point(point) {
          let date_time = point.time.split(' ');
          let date_format = date_time[0].split('.').reverse().join('-');
          point.altitude = Number(point.altitude).toFixed(2);
          point.time = `${date_format} ${date_time[1]}`;
          point.temperature = Number(point.temperature).toFixed(3);
          point.mcu_temperature = Number(point.mcu_temperature).toFixed(3);
          return point;
        },
        update_focused_point(point) {
            this.focused_point = point;
        },
        filter_gates(selected_countries) {
          return Object.values(this.all_gates).filter(
            gateway => {
              if ('attributes' in gateway) {
                return selected_countries.includes(gateway.country_code);
              } else {
                return false;
              }
            }
          );
        }
    }
});
