<template>
  <div id="windy"></div>
</template>

<script>
import {WindyHandler} from '@/utils/windy.js';
import {PointsStore} from "@/store";
import {storeToRefs} from 'pinia';
import constants from "@/consts";

export default {
  name: "WindyMap",
  setup() {
    const points_store = PointsStore();
    const {focused_point, gateways_countries} = storeToRefs(points_store);
    return {points_store, focused_point, gateways_countries};
  },
  data() {
    return {
      windy_handler: null,
      points: [],
      constants,
    }
  },
  async mounted() {
    this.points = this.points_store.points.filter(point => point.latitude !== '' && point.longitude !== '');
    this.windy_handler = new WindyHandler(this.points);
    this.windy_handler.init_windy_map(this.points_store.gates, this.points);
  },
  watch: {
    focused_point(new_point) {
      if (this.windy_handler !== null && new_point !== undefined && new_point.latitude !== '' && new_point.longitude !== '') {
        this.windy_handler.add_point_to_balloon_path(new_point);
        this.windy_handler.change_map_center(new_point);
      }
    },
    gateways_countries(countries) {
      if (this.windy_handler !== null) {
        this.windy_handler.delete_lora_gateway_clusters();
        let selected_countries = countries.map(country => country.alpha2.toLowerCase());
        let gates = this.points_store.filter_gates(selected_countries);
        this.windy_handler.create_lora_gateway_clusters(gates);
      }
    }
  }
}
</script>

<style scoped lang="scss">
#windy {
  width: 100%;
  height: 100vh;
}
.leaflet-marker-icon {
  color: white;
  font-weight: bold;
}
>>> #windy #logo {
  transform: scale(0.2, 0.2) !important;
  margin-top: 0 !important;
}
</style>
