<template>
      <v-navigation-drawer
          dark
          absolute
          height="750"
          width="260"
          class="drawer"
          hide-overlay
          temporary
          v-model="show_drawer"
      >
        <v-list>
          <v-list-item class="pb-2 pl-2">
            <div class="d-flex">
              <v-img :src="require('@/assets/balon.svg')" contain max-height="60" max-width="55" class="px-0"></v-img>
                <v-btn x-small dense fab color="grey lighten-4 my-auto ml-3" @click="show_drawer = !show_drawer">
                  <v-icon color="red lighten-1">
                    {{ expanded ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right' }}
                  </v-icon>
                </v-btn>
            </div>
            <v-fade-transition>
              <v-list-item-content class="py-0 ml-3" v-if="expanded">
                <v-list-item-title class="pt-0 mb-0">team pePeak</v-list-item-title>
                <v-list-item-subtitle class="mt-1">megakamik@gmail.com</v-list-item-subtitle>
              </v-list-item-content>
            </v-fade-transition>
          </v-list-item>

          <v-divider></v-divider>
        </v-list>

        <data-points :expanded="expanded"></data-points>

        <template v-slot:append>
          <div class="pl-2 mb-4 mt-3">
            <v-btn small light rounded @click="$vuetify.goTo('.table-wrapper')">
              history <v-icon small color="red lighten-1">mdi-chevron-double-down</v-icon>
            </v-btn>
          </div>
        </template>

      </v-navigation-drawer>

</template>

<script>
import DataPoints from "@/components/DataPoints";
export default {
  name: "AppBar",
  components: {DataPoints},
  props: ["visible"],
  data() {
    return {
      expanded: true,
    }
  },
  computed: {
    show_drawer: {
      get() {
        return this.visible;
      },
      set(value) {
        this.$emit('visible', value);
      }
    }
  },
}
</script>

<style scoped lang="scss">
.border-white {
  border: 1px solid white;
  border-radius: 100%;
}
.drawer {
  &:before {
    content: '';
    border-left: 1px dashed white;
    height: 90%;
    width: 1px;
    position: absolute;
    left: 18px;
    //top: 6%;
   }
}
.v-navigation-drawer--mini-variant.v-navigation-drawer--custom-mini-variant .v-list-item {
  justify-content: left;
}
.v-btn--fab.v-size--x-small {
  width: 22px;
  height: 22px;
}
.v-list-item__subtitle {
  font-size: 0.7em !important;
}
</style>
