import Vue from 'vue';
import App from './App.vue';
import vuetify from '@/plugins/vuetify';

import axios from 'axios';
import VueAxios from 'vue-axios';
Vue.use(VueAxios, axios);

import Router from "vue-router";
import router from './router';
Vue.use(Router, router);

import { createPinia, PiniaVuePlugin } from 'pinia';
const pinia = createPinia();
Vue.use(PiniaVuePlugin);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies, { expire: '5d'});

Vue.config.productionTip = false

import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Highcharts from 'highcharts/highstock';

NoDataToDisplay(Highcharts);

new Vue({
  vuetify,
  pinia,
  router,
  render: h => h(App),
}).$mount('#app')
